import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import Seravek from "./Seravek.ttc";
let ffmpeg;
let loadPromise;
let currentProgressCallback;
function check(execResult) {
    if (execResult !== 0) {
        throw new Error("Non-zero return code");
    }
}
window.addEventListener("beforeunload", function () {
    ffmpeg.terminate();
}, false);
export async function initFFmpeg() {
    ffmpeg = new FFmpeg();
    _ffmpegReload();
    Object.entries({
        ffmpegReload,
        ffmpegLoadFile,
        ffmpegLoadUrl,
        ffmpegUnloadFile,
        ffmpegExec,
    }).map(([name, fn]) => {
        window[name] = fn;
    });
}
async function ffmpegLoadFile(name, binData) {
    await loadPromise;
    await ffmpeg.writeFile(name, binData);
}
async function ffmpegLoadUrl(name, url, onProgress) {
    try {
        await loadPromise;
        currentProgressCallback = onProgress;
        await ffmpeg.writeFile(name, await fetchFile(url));
    }
    finally {
        currentProgressCallback = undefined;
    }
}
async function ffmpegUnloadFile(name) {
    await loadPromise;
    await ffmpeg.deleteFile(name);
}
async function ffmpegExec(args, outfile, onProgress) {
    try {
        await loadPromise;
        currentProgressCallback = onProgress;
        check(await ffmpeg.exec(args));
        if (outfile !== "") {
            return ffmpeg.readFile(outfile);
        }
    }
    finally {
        currentProgressCallback = undefined;
    }
}
async function _ffmpegReload() {
    ffmpeg.on("log", ({ message }) => {
        console.log(message);
    });
    currentProgressCallback = undefined;
    ffmpeg.on("progress", ({ progress }) => {
        if (currentProgressCallback == undefined) {
            return;
        }
        currentProgressCallback(Math.min(progress, 1));
    });
    loadPromise = ffmpeg.load({
        coreURL: await toBlobURL(`https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm/ffmpeg-core.js`, "text/javascript"),
        wasmURL: await toBlobURL(`https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm/ffmpeg-core.wasm`, "application/wasm"),
    });
    loadPromise.then(async () => {
        ffmpeg.writeFile("/tmp/Seravek.ttc", await fetchFile(Seravek));
    });
}
async function ffmpegReload() {
    ffmpeg.terminate();
    await _ffmpegReload();
}
