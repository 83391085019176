import { initFFmpeg } from "./ffmpeg";
let alreadyInit = false;
async function insertOtherScripts() {
    if (alreadyInit) {
        return;
    }
    const stripe = document.createElement("script");
    stripe.src = "https://js.stripe.com/v3/pricing-table.js";
    stripe.crossOrigin = "true";
    stripe.async = true;
    const hlsJs = document.createElement("script");
    hlsJs.src = "https://cdn.jsdelivr.net/npm/hls.js@latest";
    hlsJs.crossOrigin = "true";
    hlsJs.async = true;
    const posthogScript = document.createElement("script");
    posthogScript.src =
        "https://cdn.jsdelivr.net/npm/posthog-js@1.141.0/dist/array.full.js";
    posthogScript.crossOrigin = "true";
    posthogScript.async = true;
    posthogScript.onload = function () {
        window.posthog.init("phc_CJ20PbvZ4JwQ3UsvHpoms43lqaWelKHwaZS45IxvAYJ", {
            api_host: "https://eu.i.posthog.com",
            person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
        });
    };
    [stripe, hlsJs, posthogScript].map((script) => document.body.appendChild(script));
    initFFmpeg();
    alreadyInit = true;
}
window.addEventListener("flutter-first-frame", insertOtherScripts);
